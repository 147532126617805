export const PROFIT_OVERVIEW_CHANGE_SEARCH = 'PROFIT_OVERVIEW_CHANGE_SEARCH'
export const PROFIT_OVERVIEW_LOAD_PRODUCTS = 'PROFIT_OVERVIEW_LOAD_PRODUCTS'

export const PROFIT_OVERVIEW_TOGGLE_PRODUCT_FILTER =
  'PROFIT_OVERVIEW_TOGGLE_PRODUCT_FILTER'
export const PROFIT_OVERVIEW_SELECT_PRODUCT = 'PROFIT_OVERVIEW_SELECT_PRODUCT'

export const PROFIT_OVERVIEW_SET_ACTIVE_SUMMARY_TAB =
  'PROFIT_OVERVIEW_SET_ACTIVE_SUMMARY_TAB'

export const PROFIT_OVERVIEW_SET_INSIGHTS = 'PROFIT_OVERVIEW_SET_INSIGHTS'

export const PROFIT_OVERVIEW_UPDATE_CHART_VIEW =
  'PROFIT_OVERVIEW_UPDATE_CHART_VIEW'

export const PROFIT_OVERVIEW_PENDING_PPC_TOTALS =
  'PROFIT_OVERVIEW_PENDING_PPC_TOTALS'
export const PROFIT_OVERVIEW_PENDING_PPC_CHART =
  'PROFIT_OVERVIEW_PENDING_PPC_CHART'
export const PROFIT_OVERVIEW_RECEIVE_PPC_TOTALS =
  'PROFIT_OVERVIEW_RECEIVE_PPC_TOTALS'
export const PROFIT_OVERVIEW_UPDATE_PPC_CHART =
  'PROFIT_OVERVIEW_UPDATE_PPC_CHART'
export const PROFIT_OVERVIEW_COMPLETED_PPC_TOTALS =
  'PROFIT_OVERVIEW_COMPLETED_PPC_TOTALS'
export const PROFIT_OVERVIEW_COMPLETED_PPC_CHART =
  'PROFIT_OVERVIEW_COMPLETED_PPC_CHART'

export const PROFIT_OVERVIEW_SHOW_PPC_MODAL = 'PROFIT_OVERVIEW_SHOW_PPC_MODAL'
export const PROFIT_OVERVIEW_CLOSE_PPC_MODAL = 'PROFIT_OVERVIEW_CLOSE_PPC_MODAL'
export const PROFIT_OVERVIEW_SET_PPC_SYNC_STATUS =
  'PROFIT_OVERVIEW_SET_PPC_SYNC_STATUS'

export const PROFIT_OVERVIEW_SET_PRODUCT_SELECTED_COLUMNS =
  'PROFIT_OVERVIEW_SET_PRODUCT_SELECTED_COLUMNS'

export const PROFIT_OVERVIEW_SET_PRODUCT_FILTER =
  'PROFIT_OVERVIEW_SET_PRODUCT_FILTER'

export const PRODUCTS_TABLE_LOAD_PAGINATION = 'PRODUCTS_TABLE_LOAD_PAGINATION'
export const SET_PRODUCTS_TABLE_PAGE = 'SET_PRODUCTS_TABLE_PAGE'
export const SET_PRODUCTS_SORT_COLUMN = 'SET_PRODUCTS_SORT_COLUMN'
export const SET_PRODUCTS_TABLE_SEARCH = 'SET_PRODUCTS_TABLE_SEARCH'
export const RESET_PROFIT_OVERVIEW = 'RESET_PROFIT_OVERVIEW'

export const SET_PROFIT_OVERVIEW_V2_STATE = 'SET_PROFIT_OVERVIEW_V2_STATE'
